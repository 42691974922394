import { useRouter } from "next/router";
import { useIsNavMobile } from "src/businesslogics/_layout/window";
import NavMobile from "src/components/templates/navigation/Nav.mobile";

// nav 노출 조건 정리
/**
 * [홈]
 * - / (O)
 * [AITC]
 * - /aitc (O)
 * - /aitc/course (O)
 * [스터디 클럽]
 * - /study-club (O)
 * [강의 탐색]
 * - /catalog (O)
 * [블로그]
 * - /blog (O)
 * - /blog/category/[category] (O)
 * - /blog/[url_title] (X)
 * [커뮤니티]
 * - /community/[channelName]/[category] (O)
 * - /community/[channelName]/[category]/[postId]/[title] (X)
 * [이벤트]
 * - /event (O)
 * [전시]
 * - /exhibition (O)
 */

// 1 depth
const mainPagePathname = [
  "/",
  "/catalog/[businessSlug]",
  "/aitc",
  "/aitc/course",
  "/study/hub",
  "/blog",
  "/exhibition",
  "/event",
];
// 2 depth
const detailPagePathname = [
  "/community/[channelName]/[category]",
  "/blog/category/[category]",
];

export const Navigation = () => {
  const router = useRouter();
  const isNavMobile = useIsNavMobile();
  const { pathname, asPath } = router;
  const showNav =
    [...mainPagePathname, ...detailPagePathname].includes(pathname) ||
    asPath.includes("/site/kdc-study");

  return isNavMobile && showNav && <NavMobile />;
};
