import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getCookie } from "@/businesslogics/_common/window/cookie";
import { QuestionType } from "@/components/organisms/_layout/UserProfileSurvey/UserProfileSurvey.model";

export const CreateIdeInstanceOption: AxiosRequestConfig = {
  baseURL: `${process.env.ONLINE_API_URL}/v2`,
  timeout: 100000,
  headers: Object.assign({ "Content-Type": "application/json" }),
};

function getJwtTokenFromCookie() {
  const token = getCookie("token");
  if (token) {
    return { Authorization: "Bearer " + token };
  }
  return {};
}

export const privateInstance = axios.create(CreateIdeInstanceOption);

privateInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      config.headers = Object.assign(
        {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        getJwtTokenFromCookie(),
      );
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);
const submitUserProfileSurvey = async (questions) => {
  const data: { [key: string]: any[] } = {};
  questions.map((question) => {
    const result = question.selectedAnswer.map((item) => item.answerContent);
    if (question.tag !== null)
      data[question.tag] =
        question.questionType === QuestionType.Single ? result[0] : result;
  });
  try {
    const response: AxiosResponse = await privateInstance.post(
      `/profile`,
      data,
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const useSubmitUserProfileSurvey = (questions) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return submitUserProfileSurvey(questions);
    },
    {
      onSuccess: (result) => {},
      onError: (error) => {
        console.log(error, error);
      },
    },
  );
};
