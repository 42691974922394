export class Answer {
  answerContent: string;
  nextQuestion: Question;
  allowMultipleSelection: boolean; // 복수 선택 질문에서 한 응답을 선택할 때 다른 옵션들의 선택이 해제되어야 하는 기능
  constructor(
    answerContent: string,
    nextQuestion: Question,
    allowMultipleSelection: boolean,
  ) {
    this.answerContent = answerContent;
    this.nextQuestion = nextQuestion;
    this.allowMultipleSelection = allowMultipleSelection;
  }
}

export enum QuestionType {
  Multiple,
  Single,
  Intro,
  Done,
}

export class Question {
  questionNumber: number;
  answerDisplayStyle: string;
  answerOptions: Answer[];
  questionType: QuestionType;
  tag: string;

  questionContent: string;
  selectedAnswer: Answer[] = [];

  constructor(
    questionNumber: number,
    questionContent: string,
    answerDisplayStyle: string,
    quesitonType: QuestionType,
    tag: string,
  ) {
    this.questionNumber = questionNumber;
    this.questionContent = questionContent;
    this.answerDisplayStyle = answerDisplayStyle;
    this.questionType = quesitonType;
    this.tag = tag;
  }

  setAnswerOptions(answerOptions: Answer[]) {
    this.answerOptions = answerOptions;
  }

  selectAnswer(answer: Answer) {
    const findIndex = this.selectedAnswer.indexOf(answer);
    if (findIndex !== -1) {
      this.selectedAnswer.splice(findIndex, 1);
      return;
    }

    if (answer.allowMultipleSelection)
      if (this.selectedAnswer[0]?.allowMultipleSelection === false)
        this.selectedAnswer = [answer];
      else this.selectedAnswer?.push(answer);
    else this.selectedAnswer = [answer];
  }

  getNextQuestion() {
    return this.selectedAnswer[0]?.nextQuestion;
  }

  clear() {
    this.selectedAnswer = [];
  }
}

export const intro = new Question(
  1,
  "반가워요! {이름}님에 대해<br>좀 더 알려주시겠어요?",
  "intro",
  QuestionType.Intro,
  null,
);

export const done = new Question(
  99,
  "마지막 질문입니다.",
  "done",
  QuestionType.Done,
  null,
);
export const job = new Question(
  2,
  "{이름}님, <br>어떤 일을 하고 계신가요?",
  "list",
  QuestionType.Single,
  "current_job",
);

export const jobDetail = new Question(
  3,
  "어떤 일을 하고 계신지<br> 좀 더 자세히 알려주세요!",
  "grid",
  QuestionType.Multiple,
  "job_detail",
);

export const interestTopics = new Question(
  3,
  "앞으로 공부해보고 싶은 분야를 <br>알려주세요!",
  "list",
  QuestionType.Multiple,
  "future_learning_goals",
);

export const experienceCoding = new Question(
  4,
  "코딩을 배워본<br>경험이 있나요?",
  "list",
  QuestionType.Single,
  "experience_learning_coding",
);

export const experienceStudy = new Question(
  4,
  "어떤 분야를<br> 공부해보셨나요?",
  "grid",
  QuestionType.Multiple,
  "field_of_study",
);

/* 질문 응답 세팅 */
// 인트로 질문은 초기 세팅 값 선택
intro.setAnswerOptions([new Answer("시작", job, false)]);
intro.selectAnswer(intro.answerOptions[0]);

job.setAnswerOptions([
  new Answer("학생", interestTopics, false),
  new Answer("직장인 또는 프리랜서", jobDetail, false),
  new Answer("모두 해당되지 않아요", interestTopics, false),
]);

jobDetail.setAnswerOptions([
  new Answer("앱 개발", interestTopics, true),
  new Answer("웹 개발", interestTopics, true),
  new Answer("게임 개발", interestTopics, true),
  new Answer("마케팅/MD", interestTopics, true),
  new Answer("기획/전략", interestTopics, true),
  new Answer("데이터 분석", interestTopics, true),
  new Answer("디자인", interestTopics, true),
  new Answer("창업", interestTopics, true),
  new Answer("기타", interestTopics, true),
]);

interestTopics.setAnswerOptions([
  new Answer("웹이나 앱으로 결과물을 만들고 싶어요", experienceCoding, true),
  new Answer("직접 게임을 만들어 보고 싶어요", experienceCoding, true),
  new Answer("데이터를 추출/분석하고 싶어요", experienceCoding, true),
  new Answer(
    "디자이너 취업/협업을 위해 공부하고 싶어요",
    experienceCoding,
    true,
  ),
  new Answer("반복적인 업무를 효율화하고 싶어요", experienceCoding, true),
  new Answer("인공지능을 제작/활용해 보고 싶어요", experienceCoding, true),
  new Answer("자격증을 취득하고 싶어요", experienceCoding, true),
  new Answer("코딩테스트를 준비하고 싶어요", experienceCoding, true),
  new Answer("창업에 도전하고 싶어요", experienceCoding, true),
  new Answer("아직은 잘 모르겠어요", experienceCoding, false),
]);
experienceCoding.setAnswerOptions([
  new Answer("배워본 경험이 없어요", done, false),
  new Answer("배워본 경험은 있지만 기초가 부족해요", experienceStudy, false),
  new Answer(
    "3개월 이상 배운 경험이 있고, 기초는 충분해요",
    experienceStudy,
    false,
  ),
]);

experienceStudy.setAnswerOptions([
  new Answer("Flutter", done, true),
  new Answer("Python/Django", done, true),
  new Answer("SQL", done, true),
  new Answer("C#", done, true),
  new Answer("노코드", done, true),
  new Answer("Java/Spring", done, true),
  new Answer("HTML/CSS", done, true),
  new Answer("JavaScript", done, true),
  new Answer("Kotlin", done, true),
  new Answer("TypeScript", done, true),
  new Answer("ChatGPT/AI", done, true),
  new Answer("기타", done, true),
]);

type CourseInfo = {
  nextUrl: string;
  title: string;
  courseId: string;
};

// TODO 표최자 반영
const gpt300Online: CourseInfo = {
  nextUrl: "/online/gpt_300_online",
  title: "ChatGPT 300% 활용하기",
  courseId: "123",
};

const webBasic: CourseInfo = {
  nextUrl: "/online/web_basic",
  title: "[왕초보] 웹개발 종합반",
  courseId: "123",
};

const app: CourseInfo = {
  nextUrl: "/online/app",
  title: "앱개발 종합반",
  courseId: "123",
};

const data: CourseInfo = {
  nextUrl: "/online/data_v2",
  title: "데이터 분석 종합반",
  courseId: "123",
};

export type ModalBannerType = {
  logKey: string;
  key: string;
  firstCard: CourseInfo;
  secondCard: CourseInfo;
};

export const modalBannerTypeData: Record<string, ModalBannerType> = {
  "아직은 잘 모르겠어요": {
    logKey: "kdc",
    key: "unknown",
    firstCard: gpt300Online,
    secondCard: webBasic,
  },
  "웹이나 앱으로 결과물을 만들고 싶어요": {
    logKey: "kdc",
    key: "webapp",
    firstCard: webBasic,
    secondCard: app,
  },
  "데이터를 추출/분석하고 싶어요": {
    logKey: "kdc",
    key: "data",
    firstCard: gpt300Online,
    secondCard: data,
  },
};
