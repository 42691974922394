import styled from "@emotion/styled";

export default function GnbSpartaLogo({
  showMainLogo = true,
}: {
  showMainLogo?: boolean;
}) {
  return (
    <LogoWrapper>
      <svg
        width="84"
        height="30"
        viewBox="0 0 84 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M70.4005 6.3369H68.5198V7.25246H70.4005V6.3369Z"
          fill="#E8344E"
        />
        <path
          d="M70.4005 8.28493H68.5198V13.3153H70.4005V8.28493Z"
          fill="#E8344E"
        />
        <path
          d="M58.3751 15.3758H56.4944V22.441H58.3751V15.3758Z"
          fill="#E8344E"
        />
        <path
          d="M53.3757 12.3989V9.45458H55.4136V8.53902H52.4805L51.4543 9.44319V12.2543V12.3989L52.4805 13.3149H55.4136V12.3993H53.3745V12.3989H53.3757Z"
          fill="#E8344E"
        />
        <path
          d="M75.4258 8.38443H73.5821H71.7012V9.29958V13.3148H73.5821V9.29958H74.5715V13.3158H76.4522V9.28819L75.4258 8.38402"
          fill="#E8344E"
        />
        <path
          d="M70.4385 21.5299V18.3096H70.4398L70.4385 18.3085V18.3049H70.4347L69.4689 17.4319V17.4307H69.4671L69.4655 17.429V17.4307H67.3816V15.3755H65.5007V22.4407H66.5178H69.4689L70.4381 21.5295L70.4385 21.5299ZM68.5574 18.3467V21.5261H67.3816V18.3467H68.5574Z"
          fill="#E8344E"
        />
        <path
          d="M81.4798 15.3577L82.4696 14.4439V8.38077H78.5598L77.5352 9.28369H77.5334V13.4209H80.5883V14.4417H78.528V15.3573H81.479M79.4147 12.5056V9.29633H80.5889V12.5056H79.4147Z"
          fill="#E8344E"
        />
        <path
          d="M57.456 8.53902L56.4298 9.44319V12.3984L57.456 13.3145H60.3744L61.4007 12.3984V9.44319L60.3744 8.53902H57.456ZM59.4788 12.3989V12.3997H58.3511V9.45458H59.4788V12.3989Z"
          fill="#E8344E"
        />
        <path
          d="M65.5036 6.35612V8.53906H63.4694L62.4436 9.44282H62.4432V12.3998H62.444L63.4661 13.312V13.3145H63.469L63.4698 13.3153V13.3145H65.504H67.4254V6.35612H65.504H65.5036ZM65.5036 12.3993H64.365V9.45421H65.5036V12.3993Z"
          fill="#E8344E"
        />
        <path
          d="M53.335 21.5414V18.3467H55.4341V17.4311H52.4805L51.4543 18.3353V21.5414H51.4547L52.4805 22.4574H55.4341V21.5418H53.335"
          fill="#E8344E"
        />
        <path
          d="M64.3985 17.4311H62.5178V21.5367H61.4039V17.4311H59.5232V21.5345H59.5203L59.5232 21.5371V21.5404H59.5265L60.5445 22.4489V22.4509H63.4955V22.4413H64.3985V17.4311Z"
          fill="#E8344E"
        />
        <path
          d="M21.9723 12.4537H21.2382V13.1922H20.5038V14.6696H22.7067V13.1922H21.9723V12.4537Z"
          fill="#E8344E"
        />
        <path
          d="M16.9722 12.4537H14.8418V13.9192H16.9722V12.4537Z"
          fill="#E8344E"
        />
        <path
          d="M28.9115 13.1928V12.4537H26.7826V13.9313H28.9164L28.9115 13.1928Z"
          fill="#E8344E"
        />
        <path
          d="M38.9673 12.4537H38.2329V13.1922H37.4988V14.6696H39.7017V13.1922H38.9673V12.4537Z"
          fill="#E8344E"
        />
        <path
          d="M40.7096 2.30769L2.81196 9.8003L0 19.1801L6.32557 26.4646L31.5071 21.4861L32.8949 26.4566L39.5543 19.895L44.5551 18.9065L47.2673 9.85971L40.7096 2.30769ZM12.2121 13.1924H10.7742V12.4539H8.84347V13.9311H11.5679V14.6696H12.2117V16.147H11.5679V16.8855H8.04927V16.147H7.36649L7.36817 15.4083H8.8062L8.84347 16.147H10.7369V14.6696H8.04927V13.9311H7.40544V12.4539H8.04927V11.7153H11.5679V12.4539H12.2117V13.1924H12.2121ZM18.1137 13.9193H17.6676V14.6578H14.8419V16.8855H13.6798V12.4602H14.2031V11.7149H17.6676V12.4535H18.1137V13.9189V13.9193ZM23.9463 16.8855H22.7069V15.4083H20.504V16.8855H19.2646V13.1924H19.7696V12.4539H20.504V11.7153H22.7069V12.4539H23.4412V13.1924H23.9463V16.8855ZM30.38 16.8855H28.9113L28.9164 16.1415H28.2511V15.4955H27.6283V14.6702H26.7826V16.8855H25.5821V12.4602H26.1103V11.7149H29.646V12.4535H30.1513V13.9134H29.603V14.5537H28.9119V15.4079H29.646V16.1464H30.3804V16.8851L30.38 16.8855ZM35.6486 12.4539H34.1416V16.8855H32.7889V12.4539H31.2428V11.7153H35.6486V12.4539ZM40.9404 16.8855H39.701V15.4083H37.4981V16.8855H36.2587V13.1924H36.7637V12.4539H37.4981V11.7153H39.701V12.4539H40.4353V13.1924H40.9404V16.8855Z"
          fill="#E8344E"
        />
        <path
          d="M39.7626 23.4655V23.9046H40.1811V24.3427H40.5999V24.7817H41.0183V25.2208H40.5999V25.6602H40.1811V26.0993H39.7626V26.5383H38.9252V26.1002H39.3442V25.6612H39.7626V25.2217H40.1811V24.7827H39.7626V24.3427H39.3442V23.9046H38.9252V23.4655H39.7626Z"
          fill="#E8344E"
        />
        <path
          d="M38.0038 23.5043H37.2746V23.9278H36.8628V24.3592H36.4721V24.7832H36.0603V25.2142H35.6701V25.6379H35.2584V26.0695H34.868V26.5005H35.5973V26.0695H35.9873V25.6456H36.3994V25.2142H36.789V24.7906H37.2013V24.3592H37.5913V23.9353H38.0034V23.5043H38.0038Z"
          fill="#E8344E"
        />
        <path
          d="M30.3792 26.5387V26.0996H29.9603V25.6606H29.5417V25.2211H29.1233V24.7821H29.5417V24.3423H29.9603V23.904H30.3792V23.465H31.2164V23.904H30.7976V24.3423H30.3792V24.7811H29.9603V25.2202H30.3792V25.6596H30.7976V26.0987H31.2164V26.5377L30.3792 26.5387Z"
          fill="#E8344E"
        />
      </svg>
    </LogoWrapper>
  );
}

export const GnbSpartaLogoM = () => {
  return (
    <LogoWrapper>
      <svg
        width="41"
        height="26"
        viewBox="0 0 41 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0426 10.7932H18.4063V11.4333H17.7699V12.7136H19.6791V11.4333H19.0426V10.7932Z"
          fill="#E8344E"
        />
        <path
          d="M14.7093 10.7932H12.8629V12.0632H14.7093V10.7932Z"
          fill="#E8344E"
        />
        <path
          d="M25.0566 11.4338V10.7932H23.2115V12.0738H25.0608L25.0566 11.4338Z"
          fill="#E8344E"
        />
        <path
          d="M33.7716 10.7932H33.1352V11.4333H32.4989V12.7136H34.4081V11.4333H33.7716V10.7932Z"
          fill="#E8344E"
        />
        <path
          d="M35.2817 2L2.43703 8.49359L0 16.6227L5.48216 22.936L27.3061 18.6212L28.5089 22.929L34.2804 17.2423L38.6144 16.3857L40.965 8.54508L35.2817 2ZM10.5838 11.4334H9.33763V10.7934H7.66434V12.0736H10.0255V12.7137H10.5835V13.994H10.0255V14.6341H6.97603V13.994H6.38429L6.38575 13.3538H7.63204L7.66434 13.994H9.30529V12.7137H6.97603V12.0736H6.41805V10.7934H6.97603V10.1532H10.0255V10.7934H10.5835V11.4334H10.5838ZM15.6986 12.0634H15.3119V12.7034H12.863V14.6341H11.8559V10.7988H12.3094V10.1529H15.3119V10.793H15.6986V12.0631V12.0634ZM20.7534 14.6341H19.6793V13.3538H17.7701V14.6341H16.696V11.4334H17.1336V10.7934H17.7701V10.1532H19.6793V10.7934H20.3157V11.4334H20.7534V14.6341ZM26.3293 14.6341H25.0564L25.0609 13.9893H24.4843V13.4294H23.9445V12.7142H23.2116V14.6341H22.1711V10.7988H22.6289V10.1529H25.6932V10.793H26.1311V12.0583H25.656V12.6132H25.057V13.3535H25.6932V13.9935H26.3297V14.6337L26.3293 14.6341ZM30.8955 10.7934H29.5894V14.6341H28.417V10.7934H27.0771V10.1532H30.8955V10.7934ZM35.4817 14.6341H34.4075V13.3538H32.4983V14.6341H31.4242V11.4334H31.8619V10.7934H32.4983V10.1532H34.4075V10.7934H35.044V11.4334H35.4817V14.6341Z"
          fill="#E8344E"
        />
        <path
          d="M34.4609 20.3368V20.7173H34.8235V21.097H35.1865V21.4775H35.5492V21.858H35.1865V22.2388H34.8235V22.6193H34.4609V22.9998H33.7351V22.6202H34.0983V22.2397H34.4609V21.8588H34.8235V21.4783H34.4609V21.097H34.0983V20.7173H33.7351V20.3368H34.4609Z"
          fill="#E8344E"
        />
        <path
          d="M32.9367 20.3704H32.3046V20.7374H31.9478V21.1113H31.6091V21.4787H31.2523V21.8522H30.9142V22.2195H30.5573V22.5935H30.219V22.967H30.851V22.5935H31.189V22.2261H31.5462V21.8522H31.8838V21.4852H32.2412V21.1113H32.5791V20.7439H32.9363V20.3704H32.9367Z"
          fill="#E8344E"
        />
        <path
          d="M26.3287 23.0002V22.6197H25.9657V22.2392H25.6029V21.8583H25.2402V21.4778H25.6029V21.0966H25.9657V20.7168H26.3287V20.3363H27.0543V20.7168H26.6913V21.0966H26.3287V21.477H25.9657V21.8575H26.3287V22.2383H26.6913V22.6189H27.0543V22.9994L26.3287 23.0002Z"
          fill="#E8344E"
        />
      </svg>
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4.25px;
`;
